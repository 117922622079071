/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@at-root {
  @-ms-viewport {
    width: device-width;
  } // stylelint-disable-line at-rule-no-vendor-prefix
}

$enable-grid-classes: true !default;

@import 'functions';

@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';

@import 'grid';
@import 'flex';
