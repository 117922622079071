.contact-list {
  .actions {
    text-align: right;
    width: 100%;
    @include respond-to('sm') {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .fa-ellipsis-h {
      cursor: pointer;
      color: $dark-gray;
      font-size: 18px;
    }
  }
}
