.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0, 0.75);
  z-index: 10;
  cursor: zoom-out;
  overflow: scroll;

  .modal-content {
    height: auto;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    outline: none;
    cursor: default;
    padding: 30px 30px 100px 30px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    background: white;
    margin-bottom: 60px;

    &.no-pad {
      padding: 0;
      header {
        display: none;
      }
    }

    &-sm {
      width: 400px;
    }

    &-md {
      width: 600px;
    }

    &-lg {
      width: 800px;
    }

    @include respond-to('sm') {
      width: 90%;
      padding-left: 20px;
      padding-right: 20px;
    }

    hr {
      border: 0;
      height: 0;
      border-top: 2px dotted darken($light-blue, 10%);
    }

    .fa-times {
      position: absolute;
      top: -20px;
      right: -20px;
      cursor: pointer;
      font-size: 22px;
      color: white;

      @include respond-to('sm') {
        right: 0;
        top: -30px;
      }
    }
  }

  header {
    background: white;
    margin: -30px -30px 24px -30px;
    letter-spacing: 0.5px;
    font-size: 20px;
    font-weight: bold;
    color: $dark-gray;
    padding: 26px 30px 24px 30px;
    background: $light-blue;
    border-bottom: 2px solid darken($light-blue, 3%);

    @include respond-to('sm') {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px;
    background: $light-blue;
    margin-top: 20px;
    text-align: right;
    border-top: 2px solid darken($light-blue, 3%);
  }
}

.exit-popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: $blue;
  transform: translateY(100%);
  transition: all 800ms;

  &.shown {
    transform: translateY(0);
  }

  @include respond-to('sm') {
    top: auto;
  }

  &-content {
    padding: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 600px;
    width: 70%;
    transform: translate(-50%, -50%);
    text-align: center;

    strong,
    p {
      color: white;
      line-height: 1.4;
    }

    strong {
      font-size: 22px;
      margin-bottom: 16px;
      letter-spacing: 2px;
    }

    p {
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    @include respond-to('sm') {
      position: static;
      width: 100%;
      transform: none;

      form {
        display: flex;
        margin-top: 24px;
        flex-direction: column;
      }
    }
  }

  .fa {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 20px;
    color: white;

    @include respond-to('sm') {
      font-size: 18px;
      top: 0;
      right: 0;
      background: $blue;
      padding: 6px 8px;
    }
  }
}

.envelope {
  position: relative;
  margin: 30px auto 20px auto;
  width: 98px;
  height: 66px;
  background: #c94548;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 1px #c94548 inset;
  text-align: left;
  animation: wiggle 0.3s 0.2s infinite;
}
.envelope:before,
.envelope:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-width: 33px 49px;
}
.envelope:before {
  border-bottom-color: #c94548;
  top: -100%;
  left: 0px;
}
.envelope:after {
  border-right-color: #ed4c50;
  border-left-color: #fa565a;
  border-bottom-color: #fa565a;
  top: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.paper {
  background: #fff;
  width: 87px;
  height: 66px;
  margin: 0 auto;
  border-radius: 5px;
  position: absolute;
  left: 6px;
  top: -33px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}
.paper:before,
.paper:after {
  content: '';
  position: absolute;
  left: 12px;
  background: #e3f1fc;
  height: 4px;
  border-radius: 5px;
}
.paper:before {
  top: 14px;
  width: 31px;
}
.paper:after {
  right: 12px;
  top: 28px;
  box-shadow: 0 8px 0 #e3f1fc, 0 16px 0 #e3f1fc, 0 24px 0 #e3f1fc, 0 40px 0 #e3f1fc;
}
