.user-role {
  font-size: 12px;
  text-transform: uppercase;
  color: #888;

  @include respond-to('sm') {
    font-size: 14px;
    color: $dark-gray;
    text-transform: capitalize;
  }
}
