.card-info {
  img {
    width: 80px;
    margin-bottom: 8px;
    margin-right: 16px;
  }

  .last-four {
    display: block;
    color: $gray;
    margin-bottom: 16px;
    letter-spacing: 2px;
    margin-top: 8px;
  }
}
