.table-wrapper {
  margin: 0 auto;

  &.hover-scale .table-row:not(.table-header):hover {
    transform: scale(1.02);
  }
}

.table {
  width: 100%;
  display: table;

  @include respond-to('sm') {
    display: flex;
    flex-wrap: wrap;
  }

  &-row {
    display: table-row;
    transition: all 250ms;

    &.invited {
      border: 2px solid rgba($orange, 0.3);
    }

    @include respond-to('sm') {
      padding: 12px 0;
      display: block;
      background: white;
      margin-bottom: 12px;
      margin-right: 12px;
      flex-grow: 1;
      flex-shrink: 0;
      width: calc(50% - 12px);
      position: relative;
      border: 1px solid #eee;
    }

    @include respond-to('xs') {
      width: 100%;
      margin-right: 0;
    }
  }

  &-cell {
    display: table-cell;
    color: $dark-gray;

    &-content {
      background: white;
      padding: 0 20px;
      margin-bottom: -1px;
      border-bottom: 1px solid $light-gray;
      border-top: 1px solid $light-gray;
      font-size: 14px;

      min-height: 70px;
      line-height: 1.4;
      display: flex;
      align-items: center;

      @include respond-to('sm') {
        border: none !important;
        padding: 0;
        min-height: 0;
      }
    }

    &:first-of-type {
      .table-cell-content {
        border-left: 1px solid $light-gray;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:last-of-type {
      .table-cell-content {
        border-right: 1px solid $light-gray;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    @include respond-to('sm') {
      padding: 2px 16px;
      display: block;
      margin-bottom: 6px;

      &:before {
        margin-bottom: 3px;
        content: attr(data-title);
        min-width: 98px;
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $gray;
        display: block;
      }
    }
  }

  &-header {
    font-weight: 900;
    color: $dark-gray;
    background: transparent;
    text-transform: capitalize;

    .table-cell {
      padding: 0 20px 12px 20px;
    }

    @include respond-to('sm') {
      display: none;

      .table-cell {
        display: none;
      }
    }
  }
}
