@import '../global/mixins';
@import '../global/variables';

.internal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-left-nav {
    border-right: 2px solid $light-gold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    background: white;
    width: 200px;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    transition: 300ms;

    @include respond-to('sm') {
      display: none;
    }

    &.open {
      @include respond-to('sm') {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 40px;
        display: flex;
      }

      .fa-times {
        display: block;
        text-align: center;
        font-size: 20px;
        padding: 24px 0;
        color: $gray;
      }
    }

    .logo {
      font-size: 28px;
      color: $dark-gray;
      margin: 30px auto;
      text-align: center;
      display: block;

      @include respond-to('sm') {
        padding: 20px;
      }
    }

    &-item {
      padding: 5px 0;
      color: $gray;
      &.draft {
        color: $red;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fa-times {
          margin-right: 16px;
          line-height: 10px;
        }
      }
      a {
        padding: 15px 0 15px 20px;
        display: block;
        color: inherit;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 900;
        line-height: 18px;
        transition: color 500ms;
        white-space: nowrap;
        overflow: hidden;

        .fa {
          margin-right: 20px;
          font-size: 18px;
        }

        &:hover {
          color: $blue;
        }

        &.active {
          color: $gold;
          background-image: linear-gradient(to right, $light-tan, lighten($light-tan, 3%));
          border-right: 3px solid $gold;
          margin-right: -2px;

          @include respond-to('sm') {
            border-right: none;
          }
        }

        &.cta {
          border-radius: 30px;
          color: $blue;
          border: 2px solid $blue;
          margin: 0 16px 0 8px;
          padding: 10px;
          background: linear-gradient(to right, $blue 50%, white 50%);
          background-size: 200% 100%;
          background-position: right bottom;
          transition: all 200ms ease-out;

          .fa {
            transition: transform 500ms ease;
          }

          &:hover {
            background-position: left bottom;
            color: white;

            .fa {
              transform: rotate(25deg);
            }
          }
        }
      }
    }

    .upsell {
      padding: 16px;
      display: flex;
      align-items: center;
      .fa {
        color: rgb(245, 172, 56);
        margin-right: 8px;
      }
      span,
      a {
        font-size: 14px;
        line-height: 1.6;
        background: linear-gradient(45deg, #e47b49 0%, #ea4c89 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      a {
        text-decoration: underline;
        border-bottom: 2px solid rgba(#ea4c89, 0.5);
      }
    }

    .bottom-content {
      padding: 20px;
      border-top: 1px solid $light-gold;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fa-question-circle {
        font-size: 20px;
        color: $blue;
      }
    }
  }

  &-header {
    margin-bottom: 30px;

    @include respond-to('sm') {
      margin-bottom: 20px;
    }

    @include respond-to('xs') {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .pull-right {
        float: none;
        order: 2;
        margin-top: 12px;
      }
    }

    h1 {
      color: $dark-gray;
      font-weight: 900;
      margin-bottom: 8px;

      @include respond-to('sm') {
        font-size: 24px;
        margin-bottom: 4px;
      }
    }

    h2 {
      color: $gray;
      font-weight: 400;
      font-size: 17px;
      letter-spacing: 0.25px;
      max-width: 500px;
      line-height: 1.4;

      @include respond-to('sm') {
        font-size: 15px;
      }
    }
  }

  .mobile-header {
    position: relative;
    box-shadow: 0 4px 2px -2px rgba(black, 0.03);
    padding: 16px;
    text-align: center;

    .logo {
      color: $dark-gray;
      font-size: 24px;
    }

    .menu-toggle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
    }
  }
}

.internal-layout {
  flex: 1 0 auto;
  background: $light-blue;
  margin-left: 200px;
  transition: 300ms;
  padding: 30px;

  @include respond-to('sm') {
    margin-left: 0;
    padding: 20px 0 80px 0;
  }
}

.imp-banner {
  background: $orange;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-left: 200px;

  > p {
    color: white;
    margin-right: 24px;
  }
}

.custom-toast {
  position: fixed;
  display: flex;
  bottom: 40px;
  right: 20px;
  background-color: white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  flex-direction: row;
  max-width: 340px;
  z-index: 1;
  overflow: hidden;
  @include box-shadow;

  .toast-text {
    padding: 12px 16px;
  }

  .toast-sidebar {
    background-color: #c9a61d;
  }

  .toast-icon {
    color: white;
    font-size: 24px;
    padding: 16px;
  }
}
