@import '../global/variables';

.support-page {
  .well {
    text-align: center;
    min-height: 240px;
  }

  .fa {
    font-size: 40px;
    color: $gold;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 10px;
    color: $blue;
  }

  p {
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $dark-gray;
  }

  .created {
    color: $gray;
    font-style: italic;
  }
}
