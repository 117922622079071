.protip {
  border: 1px solid #eee;
  margin-top: 40px;
  padding: 24px;
  font-size: 18px;
  display: inline-block;

  .fa {
    color: #3882f5;
    font-size: 24px;
    margin-top: -3px;
    margin-right: 12px;
  }

  strong {
    font-weight: 700;
    margin-right: 12px;
    display: inline;
  }

  p {
    color: $gray;
    margin-left: 26px;
    margin-top: 4px;
    font-size: 16px;
  }
}
