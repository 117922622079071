// Pagination
.pagination {
  display: flex;
  list-style: none;
  margin: 3px 0;
  padding: 3px 0;
  justify-content: center;

  .page-item,
  .break {
    margin: 3px 4px;

    span {
      display: inline-block;
      padding: 3px 3px;
    }

    a {
      border-radius: 10px;
      display: inline-block;
      padding: 3px 6px;
      text-decoration: none;
      cursor: pointer;
      outline: none;
      &:focus,
      &:hover {
        color: $gold;
      }
    }

    &.disabled {
      a {
        cursor: default;
        opacity: 0.3;
        pointer-events: none;
      }
    }

    &.active {
      a {
        background: $gold;
        color: $light-gold;
      }
    }

    &.page-prev,
    &.page-next {
      flex: 1 0 50%;
    }

    &.page-next {
      text-align: right;
    }

    .page-item-title {
      margin: 0;
    }

    .page-item-subtitle {
      margin: 0;
      opacity: 0.5;
    }
  }
}
