//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1136px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1136px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;

// END of Grid Options

// Bootstrap file imports
@import 'mixins/breakpoints';
@import 'bootstrap-grid';
