.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation: zoomIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1.5s;
}

@keyframes scroll-grid {
  0% {
    transform: translate3d(0, 0, 0);
    filter: grayscale(80%);
    opacity: 0.25;
  }

  7% {
    filter: grayscale(30%);
    opacity: 0.9;
  }

  100% {
    transform: translate3d(-2724px, 0, 0);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(2deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  to {
    opacity: 0;
  }
}

@keyframes flashBorder {
  0% {
    border-color: $red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: $red;
  }
}
