@keyframes bounce {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.preview {
  @include aspect-ratio(6, 4);
  margin-bottom: 10%;

  &-label {
    color: lighten($gray, 10%);
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 11px;
  }

  &-card,
  &-envelope {
    @include aspect-ratio(6, 4);
    position: absolute;
    width: 90%;
    left: 0;
    top: 0;
    transition: all 400ms;
    z-index: 0;
    cursor: pointer;
    // should be 100% if only one

    &:hover {
      transform: scale(1.02);
    }

    &.solo {
      width: 100%;
      cursor: default;

      &.active {
        top: 0;
      }

      &:hover {
        transform: none;
      }
    }

    .preview-content {
      box-shadow: 0 0 16px rgba(black, 0.07);
      background: $envelope-white;
      border: 1px solid #eee;
      border-radius: 2px;
      height: 100%;

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding: 30px;

      background-size: contain;

      @include respond-to('xs') {
        padding: 10px;
      }

      .message {
        line-height: 1.5;
        white-space: pre-wrap;
        position: absolute;

        @include respond-to('xs') {
          div {
            font-size: 10px !important;
          }
        }
      }
    }

    &.active {
      top: 10%;
      right: 0;
      left: auto;
      z-index: 1;
      cursor: default;
      &:hover {
        transform: none;
      }
    }
  }

  &-envelope {
    .stamp {
      width: 15%;
      position: absolute;
      right: 18px;
      top: 18px;
      transform: rotate(1deg);

      > img {
        width: 100%;
      }
    }

    .envelope-center,
    .from-address {
      line-height: 1.4;
      position: absolute;
    }

    .envelope-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 50%;
    }
  }

  &-card {
    animation: bounce 1s 0.5s 2;
    .react-card-flip {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
    .fa-refresh {
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 22px;
      color: $blue;
      cursor: pointer;
      &:hover {
        font-size: 23px;
      }
    }

    .preview-content {
      padding: 0;
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  &-empty {
    color: #aaa;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    transform: translate(-50%, -50%);
  }
}
