.api-page {
  .api-header {
    padding: 220px 0 190px 0;
    background-image: linear-gradient(to bottom, rgba(white, 0.85), rgba(white, 1)),
      url('https://res.cloudinary.com/handwrite/image/upload/q_51/v1574201485/assets/card-bg_wbz1gm.jpg');
    background-size: cover;

    @include respond-to('xs') {
      padding: 160px 0 20px;
    }

    h1 {
      font-size: 54px;
      font-weight: 900;

      @include respond-to('md') {
        font-size: 44px;
        line-height: 1.2;
      }

      @include respond-to('sm') {
        font-size: 36px;
      }

      @include respond-to('xs') {
        font-size: 28px;
      }
    }

    h2 {
      margin: 24px 0 48px 0;
      font-weight: 400;
      color: $gray;

      @include respond-to('sm') {
        margin-top: 12px;
        font-size: 18px;
        line-height: 1.4;
      }

      @include respond-to('xs') {
        font-size: 16px;
      }
    }
  }

  .scrolling-background-wrapper {
    width: 100%;
    overflow: hidden;
    margin-top: -60px;
    // opacity: 0.7;
    // filter: grayscale(50%);

    @include respond-to('xs') {
      display: none;
    }

    .scrolling-background {
      background-image: url('https://res.cloudinary.com/handwrite/image/upload/q_35/v1574266452/assets/sliding_stationery_zvcnp8.png');
      height: 130px;
      width: 8174px;
      background-size: 2724px;
      background-repeat: repeat-x;
      background-position: center;
      animation: scroll-grid 120s linear infinite;
    }
  }

  .explanation {
    padding-top: 120px;

    @include respond-to('sm') {
      padding-top: 60px;
    }

    .fa {
      font-size: 28px;
      padding: 20px;
      color: $gold;
      background: $light-gold;
      border-radius: 50%;

      @include respond-to('md') {
        display: none;
      }
    }

    strong {
      font-size: 17px;
      text-transform: uppercase;
      color: $gold;
      font-weight: 700;
      letter-spacing: 0.5px;
      display: block;
      margin-bottom: 16px;
      margin-top: 24px;
    }

    p {
      @extend .body-text-lg;
      margin-bottom: 18px;
    }

    img {
      box-shadow: 1px 1px 5px 0 rgba(26, 26, 67, 0.05), 39px 62.5px 125px -25px rgba(50, 50, 93, 0.35),
        23.4px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
      border: 8px solid white;
      display: block;
      margin: 0 auto 120px auto;
      max-width: 100%;

      @include respond-to('md') {
        max-width: 85%;
      }

      @include respond-to('sm') {
        max-width: 400px;
        margin: 0 auto;
        box-shadow: none;
      }

      @include respond-to('xs') {
        max-width: 100%;
        border: none;
        border-radius: 8px;
        @include box-shadow;
      }
    }
  }

  .price-prompt {
    background: linear-gradient(to right, $tan, lighten($tan, 13%));
    padding: 30px 0;
    font-size: 18px;
    color: $dark-gray;
    line-height: 1.5;
    letter-spacing: 0.25px;
    .link {
      color: inherit;
    }

    @include respond-to('sm') {
      font-size: 16px;
    }
  }

  .code-example {
    background: $light-blue;
    padding: 60px 0 80px 0;
    overflow: hidden;

    h3 {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
}

.get-started {
  padding: 80px 0;
  background: $dark-gray;
  border-bottom: 2px dotted rgba(white, 0.1);
  display: flex;
  justify-content: center;

  &-text {
    color: white;
    p {
      color: inherit;
      font-size: 24px;
      font-weight: 400;
      &:first-of-type {
        margin-bottom: 12px;
        color: lighten($blue, 30%);
      }

      @include respond-to('sm') {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 32px;
        text-align: center;
      }
    }
  }

  &-actions {
    text-align: right;
    @include respond-to('sm') {
      text-align: center;
    }
  }
}
