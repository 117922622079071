@import '../global/mixins';
@import '../global/variables';

$triangle-size: 8px;

.tooltip-wrapper {
  position: relative;
  &:hover {
    cursor: pointer;
    .tooltip {
      display: block;
    }
  }
}

.tooltip {
  position: absolute;
  width: max-content;
  color: $light-gray;
  padding: 8px;
  line-height: 1.5;
  background: $dark-gray;
  font-size: 11px;
  text-transform: none;
  letter-spacing: 1px;
  z-index: 3;
  border-radius: 3px;
  display: none;
  max-width: 240px;

  &-top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &-right {
    left: calc(100% + #{$triangle-size});
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-right: $triangle-size solid $dark-gray;
      border-bottom: $triangle-size solid transparent;
      border-top: $triangle-size solid transparent;
    }
  }

  &-bottom {
    top: calc(100% + #{$triangle-size});
    left: 50%;
    transform: translateX(-50%);
    &:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: $triangle-size solid $dark-gray;
      border-right: $triangle-size solid transparent;
      border-left: $triangle-size solid transparent;
    }
  }

  &-left {
    right: calc(100% + #{$triangle-size});
    top: 50%;
    transform: translateY(-50%);

    &:before {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-left: $triangle-size solid $dark-gray;
      border-bottom: $triangle-size solid transparent;
      border-top: $triangle-size solid transparent;
    }
  }
}

.banner {
  border-left: 4px solid $gray;
  color: $gray;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  display: flex;
  align-items: center;
  overflow: auto;
  background: rgba($gray, 0.05);

  &-green {
    color: $dark-gray;
    border-color: $green;
    background: rgba($green, 0.05);
  }

  &-success {
    color: $dark-gray;
    border-color: $green;
    background: rgba($green, 0.05);

    &:before {
      content: '\f00c';
      color: $green;
      margin-right: 12px;
      font: normal normal normal 18px/1 'FontAwesome';
    }
  }

  &-warning {
    color: darken($orange, 10%);
    border-color: $orange;
    background: rgba($orange, 0.05);

    &:before {
      content: '\f071';
      color: $orange;
      margin-right: 12px;
      font: normal normal normal 18px/1 'FontAwesome';
    }
  }

  &-danger {
    color: darken($red, 10%);
    border-color: $red;
    background: rgba($red, 0.05);

    &:before {
      content: '\f071';
      color: $red;
      margin-right: 12px;
      font: normal normal normal 18px/1 'FontAwesome';
    }
  }

  &.with-check {
    &:before {
      content: '\f00c';
      color: $green;
      margin-right: 12px;
      font: normal normal normal 18px/1 'FontAwesome';
    }
  }

  &.with-lock {
    &:before {
      content: '\f023';
      color: inherit;
      margin-right: 12px;
      font: normal normal normal 18px/1 'FontAwesome';
    }
  }
}

.chip {
  color: $gold;
  border: 1px dashed $gold;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 13px;
  margin-top: 8px;
}

.covid-warning {
  background: rgba($orange, 0.05);
  border: 1px solid $orange;
  padding: 16px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;

  .warning-icon {
    font-size: 36px;
    color: $orange;
    margin-right: 24px;
    flex-shrink: 0;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  strong {
    color: darken($orange, 20%);
    margin-bottom: 16px;
  }

  p {
    color: $orange;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 8px;
  }
}
