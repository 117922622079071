.shadow-right {
  position: relative;
  @include respond-to('sm') {
    padding-bottom: 16px;
  }

  &.show-shadow:after {
    content: '';
    box-shadow: inset -10px 0 11px -10px rgba(0, 0, 0, 0.3);
    width: 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .fa-arrow-circle-o-right,
  .fa-arrow-circle-o-left {
    color: $gray;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    opacity: 0.5;
    font-size: 26px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    @include respond-to('sm') {
      top: calc(100% - 26px);
      transform: none;
    }
  }

  .fa-arrow-circle-o-right {
    right: -58px;
    @include respond-to('sm') {
      right: calc(50% - 26px);
    }
  }

  .fa-arrow-circle-o-left {
    left: -58px;

    @include respond-to('sm') {
      left: calc(50% - 26px);
    }
  }
}
