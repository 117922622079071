@import 'mixins.scss';

.text-right {
  text-align: right; // comment
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase !important;
}

.capped {
  text-transform: capitalize !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.ellipsized {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.underline {
  text-decoration: underline !important;
}

.strikethrough {
  text-decoration: line-through;
}

.no-td {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.vertical-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.xs-only {
  @media (min-width: 577px) {
    display: none !important;
  }
}

.sm-only {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.hidden-xs {
  @include respond-to('xs') {
    display: none !important;
  }
}

.hidden-sm {
  @include respond-to('sm') {
    display: none !important;
  }
}

.hidden-md {
  @include respond-to('md') {
    display: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.hidden-all {
  display: none !important;
}

.italic {
  font-style: italic;
}

.well {
  background: white;
  @include box-shadow;
  padding: 24px;
  border-radius: 6px;

  &.well-np {
    padding: 0;
  }

  &.well-empty {
    background: transparent;
    border: 2px solid #eee;
    box-shadow: none;
  }

  @include respond-to('sm') {
    padding: 16px;
  }
}

.error-text {
  color: $red;
}

.link {
  text-decoration: underline;
  color: $gold;
  cursor: pointer;
}

.controls {
  position: absolute;
  top: 20px;
  right: 20px;

  @include respond-to('sm') {
    top: 12px;
    right: 16px;

    .fa {
      font-size: 13px;
    }
  }

  .fa {
    margin-left: 6px;
    color: $gray;
    cursor: pointer;
    transition: transform 200ms;
    border: 1px solid #eee;
    padding: 3px 5px;
    border-radius: 4px;
    &:hover {
      transform: scale(1.15);
    }
  }
}

.color-gray {
  color: $gray !important;
}

.color-red {
  color: $red !important;
}

.color-orange {
  color: $orange !important;
}

.color-dark-gray {
  color: $dark-gray;
}

.color-blue {
  color: $blue;
}

.color-gold {
  color: $gold;
}

.color-green {
  color: $green;
}

.color-pink {
  color: $code-pink;
}

.color-tan {
  color: $tan;
}

.body-text {
  line-height: 1.4;
  color: $dark-gray;
  font-size: 16px;
}

.body-text-lg {
  font-size: 17px;
  line-height: 1.6;
  color: $gray;

  @include respond-to('md') {
    font-size: 16px;
  }
}

.body-text-sm {
  font-size: 14px;
  line-height: 1.4;
  color: $dark-gray;
}

.clear {
  clear: both;
}

.pre-wrap {
  white-space: pre-wrap;
}

.logo {
  @include logo;
}

.cw-100 {
  width: 100px;
}

.subheader-text {
  color: $gray;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.25px;
  line-height: 1.3;

  @include respond-to('sm') {
    font-size: 16px;
  }
}

.responsive-video-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.full-page-loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(white, 0.75);
  z-index: 100;
}

.ct-text {
  line-height: 1.3; // for the toaster
}

.spacer {
  display: flex;
  justify-content: space-between;
}

.outdated-warning {
  background: $red;
  color: white;
  text-align: center;
  padding: 12px 0;
  line-height: 1.3;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.blurred {
  filter: blur(4px);
}
