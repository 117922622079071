@import 'global/variables';
@import 'global/reset';
@import 'global/mixins';
@import 'global/buttons';
@import 'global/utilities';
@import 'global/grid/index';
@import 'global/animations';

@import 'pages/send';
@import 'pages/billing';
@import 'pages/home';
@import 'pages/cards';
@import 'pages/handwriting';
@import 'pages/templates';
@import 'pages/onboarding';
@import 'pages/team';
@import 'pages/contacts';
@import 'pages/support';
@import 'pages/internal';
@import 'pages/external';
@import 'pages/api';
@import 'pages/orders';
@import 'pages/for';
@import 'pages/admin';

@import 'components/forms';
@import 'components/tooltip';
@import 'components/pricing';
@import 'components/modal';
@import 'components/dropdown';
@import 'components/overflow_scroll';
@import 'components/tabs';
@import 'components/sidebar';
@import 'components/previewer';
@import 'components/table';
@import 'components/pagination';
@import 'components/blog';
@import 'components/protip';
@import 'components/breadcrumbs';
@import 'components/phone';

@import 'third_party/nprogress';
@import 'third_party/stripe';

html,
body {
  height: 100%;
  @include lato;
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@each $i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] {
  .mt-#{$i} {
    margin-top: $i * 8 + px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 8 + px !important;
  }
}

// mobile margin utilities
@each $i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] {
  .mtx-#{$i} {
    @include respond-to('xs') {
      margin-top: $i * 8 + px !important;
    }
  }

  .mbx-#{$i} {
    @include respond-to('xs') {
      margin-bottom: $i * 8 + px !important;
    }
  }
}

@each $i in [0, 1, 2, 3, 4, 5] {
  .mr-#{$i} {
    margin-right: $i * 8 + px !important;
  }

  .ml-#{$i} {
    margin-left: $i * 8 + px !important;
  }
}
