@import '../global/mixins';
@import '../global/variables';

.card-item {
  margin-bottom: 20px;
  position: relative;

  img {
    width: 100%;
  }

  &-content {
    margin-bottom: 16px;
  }

  .frame {
    padding: 8px;
    border: 1px solid #eee;
    position: relative;

    .side-shown {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .extra-text {
        font-size: 12px;
        color: $gray;
        font-style: italic;
        display: block;
        margin-top: 5px;
      }
    }

    &-front {
      img {
        position: relative;
        z-index: 0;
      }

      .side-shown {
        background: transparent;
      }
    }
  }

  .current-side {
    color: $dark-gray;

    .selected {
      color: $gold;
      font-weight: 400;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.preview-dimensions {
  position: fixed;
  z-index: 3;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .card-holder {
    border: 1px solid #eee;
    position: relative;

    .highlighted-line {
      border: 2px solid lime;
      position: absolute;
    }
  }

  .fa-times {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 24px;
    cursor: pointer;
  }
}
