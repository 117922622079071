.onboarding {
  svg {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &-top {
    padding: 50px 0 60px 0;
    background: white;
    border-bottom: 3px solid darken($light-blue, 3%);

    @include respond-to('sm') {
      padding: 40px 0;
    }

    ul.onboarding-steps {
      text-align: center;
      margin-bottom: 40px;
      display: flex;

      @include respond-to('xs') {
        margin-bottom: 24px;
      }

      li {
        display: inline-block;
        margin-right: 20px;
        border-bottom: 2px solid rgba(black, 0.2);
        flex-grow: 1;
        flex-basis: 0;
        text-align: left;
        padding-bottom: 8px;
        text-transform: uppercase;
        color: $gray;
        font-size: 14px;

        &:nth-of-type(2n) {
          color: $gold;
          border-bottom-color: $gold;
        }
      }
    }

    h2 {
      font-size: 38px;
      line-height: 1.2;
      margin-bottom: 20px;
      color: $blue;
      position: relative;

      span {
        position: absolute;
        right: calc(100% + 15px);
      }

      @include respond-to('sm') {
        font-size: 24px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.4;
      font-weight: 300;
      letter-spacing: 0.5px;

      @include respond-to('sm') {
        font-size: 16px;
      }
    }
  }

  &-bottom {
    background: $light-blue;
    padding-top: 40px;
    padding-bottom: 40px;

    strong {
      color: $blue;
      font-size: 18px;
      line-height: 1.3;
    }
  }

  .logo {
    color: white;
    font-size: 32px;
  }

  .step-number {
    background: white;
    color: $gold;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    @include respond-to('sm') {
      margin-bottom: 12px;
    }
  }

  .help-text {
    color: $gray;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 16px;
    line-height: 1.3;
  }

  ul.horizontal-overflow {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 8px 8px 16px 0;
    position: relative;

    li {
      display: inline-block;
      margin-right: 20px;
      padding: 8px;
      cursor: pointer;
      position: relative;

      .fa-check-circle {
        position: absolute;
        bottom: 6px;
        left: 10px;
        color: $green;
        opacity: 0;
        font-size: 18px;
      }

      &.selected {
        .fa-check-circle {
          opacity: 1;
        }
      }

      p {
        margin-top: 12px;
        text-align: center;
        font-style: italic;
      }

      img {
        padding: 10px;
        box-shadow: 0 0 12px rgba(black, 0.1);
      }
    }
  }
}

.credit-card-footer {
  display: flex;
  justify-content: space-between;

  .secure-msg {
    font-size: 14px;
    color: $gray;
    position: relative;
    top: 6px;
    .fa {
      margin-right: 8px;
    }
  }
}

.slider-box {
  padding: 20px 0 80px 0;

  @include respond-to('sm') {
    padding: 20px 0 60px 0;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  p {
    font-size: 20px;
    margin-bottom: 40px;
    color: $gray;
  }
}
