$breakpoints: (
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin lato() {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}

@mixin lato-medium() {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}

@mixin lato-bold() {
  font-weight: 900;
  font-family: 'Lato', sans-serif;
}

@mixin logo() {
  font-family: 'Dancing Script', cursive;
  font-weight: 400;
}

@mixin box-shadow() {
  box-shadow: 0px 0px 6px rgba(black, 0.05);
}

@mixin box-shadow-big() {
  box-shadow: 0px 20px 35px -30px rgba(black, 0.35);
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .ar-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// $angle: The angle in degrees: 1 - 45
// $angle-position-y: The Y position of the angle: top | bottom | both
// $angle-position-x: The X position of the angle: left | right
// $angle-position-bottom-x: The X position of the bottom angle if using 'both' for $angle-position-y: left | right
// $fallback: Create a fallback for older browsers: true | false
// $fallback-color: Fallback colour for older browsers: Hex color

@mixin angle-edge(
  $angle,
  $angle-position-y,
  $angle-position-x,
  $angle-position-bottom-x: '',
  $fallback: true,
  $fallback-color: #fff
) {
  position: relative;
  overflow: hidden;

  // Converts degrees to VW, 100vw = 45deg using this technique
  @if $angle > 45 {
    $angle: 0;
    @error 'Invalid angle, it must be between 1-45';
  }
  @if $angle < 46 {
    $angle: convertDegToVW($angle);
  }

  @if $angle-position-bottom-x == '' {
    $angle-position-bottom-x: $angle-position-x;
  }

  $angle-calc-top: calc(0% + #{$angle}vw);
  $angle-calc-bottom: calc(100% - #{$angle}vw);

  $clip-path-top: 0 0, 100% 0;
  $clip-path-bottom: 100% 100%, 0 100%;

  $border-width-top: '';
  $border-width-bottom: '';
  $border-color-top: '';
  $border-color-bottom: '';

  @if $angle-position-y == 'top' or $angle-position-y == 'both' {
    @if $angle-position-x == 'left' {
      $clip-path-top: 0 $angle-calc-top, 100% 0;

      $border-width-top: #{$angle + 1}vw 100vw 0 0;
    }

    @if $angle-position-x == 'right' {
      $clip-path-top: 0 0, 100% $angle-calc-top;

      $border-width-top: #{$angle + 1}vw 0 0 100vw;
    }

    $border-color-top: $fallback-color transparent transparent transparent;
  }

  @if $angle-position-y == 'bottom' or $angle-position-y == 'both' {
    @if $angle-position-y == 'both' and $angle-position-x != $angle-position-bottom-x {
      $angle-position-x: $angle-position-bottom-x;
    }

    @if $angle-position-x == 'left' {
      $clip-path-bottom: 100% 100%, 0 $angle-calc-bottom;

      $border-width-bottom: 0 100vw #{$angle + 1}vw 0;
    }

    @if $angle-position-x == 'right' {
      $clip-path-bottom: 100% $angle-calc-bottom, 0 100%;

      $border-width-bottom: 0 0 #{$angle + 1}vw 100vw;
    }

    $border-color-bottom: transparent transparent $fallback-color transparent;
  }

  $clip-path: polygon($clip-path-top, $clip-path-bottom);
  clip-path: $clip-path;

  // Fallback for clip-path with solid colours
  @if $fallback {
    @supports not (clip-path: $clip-path) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        z-index: 10;
        display: block;
        border-style: solid;
      }

      @if $angle-position-y == 'top' or $angle-position-y == 'both' {
        &::before {
          top: 0;
          border-width: $border-width-top;
          border-color: $border-color-top;
        }
      }

      @if $angle-position-y == 'bottom' or $angle-position-y == 'both' {
        &::after {
          bottom: 0;
          border-width: $border-width-bottom;
          border-color: $border-color-bottom;
        }
      }
    }
  }
}

// Converts degrees to VW, 100vw = 45deg using this technique
@function convertDegToVW($angle) {
  @return round($angle * 2.22);
}

// Classes
.angle--top-left {
  @include angle-edge($angle: 3, $angle-position-y: 'top', $angle-position-x: 'left');
}

.angle--top-right {
  @include angle-edge($angle: 3, $angle-position-y: 'top', $angle-position-x: 'right');
}

.angle--bottom-left {
  @include angle-edge($angle: 3, $angle-position-y: 'bottom', $angle-position-x: 'left');
}

.angle--bottom-right {
  @include angle-edge($angle: 3, $angle-position-y: 'bottom', $angle-position-x: 'right');
}

.angle--both-left-left {
  @include angle-edge($angle: 3, $angle-position-y: 'both', $angle-position-x: 'left');
}

.angle--both-right-right {
  @include angle-edge($angle: 3, $angle-position-y: 'both', $angle-position-x: 'right');
}

.angle--both-left-right {
  @include angle-edge(
    $angle: 3,
    $angle-position-y: 'both',
    $angle-position-x: 'left',
    $angle-position-bottom-x: 'right'
  );
}

.angle--both-right-left {
  @include angle-edge(
    $angle: 3,
    $angle-position-y: 'both',
    $angle-position-x: 'right',
    $angle-position-bottom-x: 'left'
  );
}

.angle__content {
  display: block;
  width: 100%;
  height: auto;
}

.angle--colour {
  color: #fff;
  background-color: #2196f3;
  text-align: center;
  padding: 10% 0;
}

// despite the webkit prefixes, this works in all major browsers.
@mixin line-clamp($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin ellipsis($max-width) {
  width: #{$max-width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
