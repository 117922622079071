.template-page {
  .template-card {
    margin-bottom: 32px;
    position: relative;

    @include respond-to('sm') {
      margin-bottom: 16px;
    }

    strong {
      color: $blue;
      margin-bottom: 15px;
      display: block;

      @include respond-to('sm') {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }

    p {
      line-height: 1.4;
      color: $dark-gray;
      margin-bottom: 20px;
      white-space: pre-wrap;

      @include respond-to('sm') {
        font-size: 14px;
        margin-bottom: 12px;
      }
    }
  }

  .fa-eye,
  .fa-eye-slash {
    cursor: default;
    border: none;
  }
  .fa-eye {
    color: $red;
  }
}
