.post {
  &-cover {
    padding: 80px 0 0 0;
    text-align: left;
    position: relative;
    background: linear-gradient($light-blue, white);
    border-top: 1px solid darken($light-blue, 5%);
    overflow: hidden;

    @include respond-to('sm') {
      padding-top: 60px;
    }

    .blob-2 {
      width: 300px;
      top: -80px;
      left: 30px;
      position: absolute;
    }

    h1 {
      font-size: 48px;
      font-weight: 900;
      margin: 32px 0;
      line-height: 1.2;

      @include respond-to('sm') {
        font-size: 32px;
      }
    }

    p {
      font-weight: 400;
      line-height: 1.4;
      font-size: 18px;
      color: darken($gray, 12%);
      margin-bottom: 16px;

      @include respond-to('sm') {
        font-size: 18px;
      }

      strong {
        display: block;
        margin-bottom: 16px;
        font-weight: 900;
        font-size: 24px;

        @include respond-to('sm') {
          font-size: 18px;
        }
      }
    }
  }

  &-date {
    position: relative;
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: right;

    @include respond-to('sm') {
      margin-top: 60px;
      margin-bottom: 60px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      right: 0;
      height: 2px;
      background: rgba($gold, 0.15);
    }

    > span {
      background: white;
      padding: 0 0 0 14px;
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      color: $gold;
      letter-spacing: 1px;
    }
  }

  .table-of-contents {
    padding-right: 24px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 24px;

    @include respond-to('sm') {
      position: static;
      margin-bottom: 32px;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: $gray;
      margin-bottom: 16px;
    }

    li {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.5px;
      border-bottom: 1px solid #ddd;
      color: $gray;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        color: $dark-gray;
      }

      &:last-of-type {
        border-bottom: none;
      }

      a {
        padding: 20px 0;
        color: inherit;
        line-height: 1.35;
      }

      .fa {
        display: none;
      }
    }
  }
}

.post-body {
  margin-bottom: 120px;

  @include respond-to('sm') {
    margin-bottom: 80px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    display: inline;
    word-wrap: break-word;
    color: $gold;
  }

  hr {
    border: 0;
    height: 0;
    border-top: 2px solid #ddd;
    margin-bottom: 24px;
  }

  h2,
  h3 {
    margin-bottom: 16px;
  }

  p {
    @extend .body-text;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  ul li {
    list-style: disc;
  }

  blockquote {
    padding: 12px 24px 24px 24px;
    margin: 40px;
    border-left: 3px solid $blue;
    border-bottom: 3px solid $blue;
    font-style: italic;

    @include respond-to('sm') {
      padding: 12px 16px 16px 16px;
      margin: 40px 24px;
    }

    > p {
      margin-bottom: 0;
      font-size: 24px;
      font-family: times, arial;
      color: $blue;
      @include respond-to('sm') {
        font-size: 18px;
      }
    }
  }

  ul,
  ol {
    margin-bottom: 32px;
    li {
      list-style-position: inside;
      @extend .body-text;
      line-height: 32px;

      > p {
        display: inline;
      }
    }
  }

  img {
    max-width: 100%;
    box-shadow: 0 0 20px rgba(black, 0.1);
    border: 6px solid white;
    display: block;
    margin: 0 auto 32px auto;
  }
}

.blog-roll {
  &-cover {
    background: $blue;
    padding: 150px 0;
    text-align: center;
    @include respond-to('sm') {
      padding: 40px 0;
    }

    h1 {
      color: white;
      font-weight: 900;
      letter-spacing: 2px;
      @include respond-to('sm') {
        font-size: 24px;
      }
    }
  }

  &-list {
    li {
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #ddd;
      text-align: center;

      a {
        color: $blue;
        font-size: 28px;
        padding: 60px 0;
        display: block;

        @include respond-to('sm') {
          font-size: 22px;
        }

        &:hover {
          text-decoration: underline;

          span {
            text-decoration: none;
          }
        }

        p {
          font-size: 16px;
          margin-top: 16px;
        }

        span {
          font-size: 16px;
          color: $dark-gray;
          margin-top: 24px;
          display: block;
        }
      }
    }
  }
}
