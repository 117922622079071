$lightest-gray: #f9f9fa;
$light-gray: #e9e9ea;
$gray-d: #dfe1e3;
$gray: #7c7d82;
$dark-gray: #262730;

$light-tan: #f7f5f1;
$tan: #e2dabf;

$light-blue: #f8fafc;
// $blue: #505e71;
$blue: #3760c8;

// $gold: #a89971;
$gold: #3760c8;
$light-gold: #DBEAFE;

$green: #00a676;
$red: #cb6b67;
$orange: #dc8035;

$code-pink: #d73a49;

$nav-height: 72px;
$mobile-nav-height: 60px;

$envelope-white: #fcfaf8;
$yellow: #f39c12;
