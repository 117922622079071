.manual-send-page,
.self-send-page {
  position: fixed;
  background: white;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background: $light-blue;

  .exit-icon {
    font-size: 22px;
    position: absolute;
    top: 20px;
    left: 20px;
    color: $gray;
    z-index: 1;

    @include respond-to('xs') {
      font-size: 18px;
      top: 12px;
    }
  }
}

.step-container {
  height: 100%;
  overflow: auto;

  > div:not([class*='col-']) {
    display: flex;
    flex-grow: 1;

    > div {
      // height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding-bottom: 100px;
      z-index: 0;
    }
  }

  .step-content {
    h2 {
      margin: 40px auto 10px auto;
      text-align: center;
      font-size: 32px;

      @include respond-to('xs') {
        font-size: 22px;
      }
    }

    > p {
      font-size: 18px;
      color: $gray;
      text-align: center;
      margin-bottom: 40px;

      @include respond-to('xs') {
        font-size: 16px;
        margin-bottom: 24px;
        line-height: 1.4;
      }
    }
  }

  .step-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 74px 16px 16px;
    background: $blue;

    .progress-bar {
      position: absolute;
      width: 300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: lighten($blue, 20%);
      border-radius: 12px;
      height: 10px;

      &-percent {
        background: $green;
        height: 10px;
        border-radius: 12px;
      }

      @include respond-to('xs') {
        display: none;
        width: 150px;
        left: 20px;
        transform: translate(0, -50%);
      }
    }
  }

  .step-menu {
    $line-height: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 10px;

    li {
      margin-right: 30px;
      line-height: $line-height;
      color: #ccc;
      position: relative;
      display: inline-block;

      &.active-step {
        color: $gold;
        .step-number {
          background: $gold;
        }
      }

      &.completed-step {
        color: $green;
        .step-number {
          background: $green;
        }
      }
    }

    .step-number {
      margin-right: 6px;
      color: white;
      background: #ccc;
      width: $line-height;
      height: $line-height;
      line-height: $line-height;
      text-align: center;
      font-size: 12px;
      border-radius: 50%;
      .fa {
        line-height: $line-height;
      }
    }
  }
}

.choice-card {
  padding: 30px 40px;
  width: 100%;
  text-align: center;
  @include box-shadow;
  background: white;
  margin-bottom: 32px;

  @include respond-to('sm') {
    padding: 32px 24px;
    margin-bottom: 16px;
  }

  > .fa {
    font-size: 50px;
    background: $lightest-gray;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 30px;
    margin-bottom: 24px;

    &-envelope-o {
      color: $gold;
    }

    &-cogs {
      color: $blue;
    }

    @include respond-to('sm') {
      padding: 20px;
      font-size: 32px;
    }
  }

  h3 {
    margin-bottom: 16px;
    color: $dark-gray;
  }

  p {
    line-height: 1.5;
    max-width: 240px;
    margin: 0 auto 32px auto;
    color: $gray;
  }
}

.overview-card {
  margin-bottom: 16px;
  strong {
    font-weight: 300;
    color: $gray;
    display: block;
    margin-bottom: 16px;
  }

  > span {
    font-size: 38px;
    letter-spacing: -1px;
    font-weight: 900;
    color: $tan;
  }
}

::placeholder {
  color: #ddd;
}

.variables {
  padding: 10px 0;
  .merge-vars {
    color: $dark-gray;
    margin-left: 8px;
    position: relative;
    top: 12px;
  }
}

.card-text-wrapper {
  background: white;
  @include box-shadow-big;
  @include aspect-ratio(6, 4);
  max-width: 8in;
  transition: all 300ms;

  background-size: 100%;
  background-repeat: no-repeat;

  textarea {
    border: none;
    font-size: 22px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    outline: none;
    display: block;
    resize: none;
    border: 1px solid white;
    border-radius: 3px;
    background: transparent;

    position: absolute;

    &.border-red {
      border: 1px solid $red;
    }

    @include respond-to('md') {
      font-size: 20px;
    }

    @include respond-to('sm') {
      font-size: 15px;
    }

    @include respond-to('xs') {
      padding: 24px;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
}

.remaining-chars {
  font-size: 13px;
  color: $gray;
}

.template-choices {
  overflow: scroll;
  max-height: 100%;
  padding: 20px;
}

.image-choice {
  width: 100%;
  margin-bottom: 40px;
  @include box-shadow-big;

  > img {
    width: 100%;
  }

  @include respond-to('xs') {
    margin-bottom: 0;
  }
}

.image-choice,
.contact-choice {
  cursor: pointer;
  transition: all 150ms;
  background: white;
  border-radius: 6px;
  position: relative;

  > .fa-check,
  .fa-minus-circle {
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: white;
    font-size: 16px;
    background: $green;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 200ms;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
  }

  .fa-minus-circle {
    display: none;
    background: transparent;
    color: $red;
    font-size: 28px;
  }

  > .fa-user {
    opacity: 0.5;
    font-size: 20px;
  }

  &:hover {
    transform: scale(1.01);

    .hide-on-hover {
      display: none;
    }

    .fa-minus-circle {
      display: block;
    }
  }

  &.selected {
    .fa {
      opacity: 1;
    }
  }
}

.empty-image {
  margin: 80px auto 0;
  width: 60%;
  display: block;
  opacity: 0.3;
}

.contact-choice {
  margin-bottom: 10px;
  @include box-shadow;
  color: $gray;
  line-height: 1.3;

  strong {
    color: $dark-gray;
  }

  > .fa-check {
    font-size: 16px;
  }
}

.action-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @include respond-to('sm') {
    flex-direction: column;
  }
}

.template-text {
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 16px;
  border: 1px solid $light-gold;
  padding: 12px;
  white-space: pre-wrap;
}

.conf-contact-list {
  padding: 20px;

  li {
    position: relative;
    padding-right: 8px;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $light-gray;
  }

  .fa-times {
    position: absolute;
    top: 0;
    right: 0;
    color: $red;
    cursor: pointer;
  }
}

.success-screen {
  position: fixed;
  z-index: 1;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: $light-gold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .fa {
    color: $green;
    font-size: 72px;
    margin-bottom: 32px;
    margin-top: -80px;
  }

  h1 {
    font-size: 60px;
    margin-bottom: 24px;

    @include respond-to('xs') {
      font-size: 42px;
    }
  }

  p {
    font-size: 22px;
    color: $gray;
    margin-bottom: 40px;

    @include respond-to('xs') {
      font-size: 18px;
      text-align: center;
      padding: 0 24px;
    }
  }

  .fetti-holder {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.top-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  line-height: 28px;
}

.preview-scroller {
  text-align: left;

  span {
    margin: 0 16px;
  }

  .fa {
    cursor: pointer;
    color: $gold;
    font-size: 28px;
  }
}

.final-actions {
  display: flex;
  justify-content: space-between;
}

.send-btn {
  position: fixed;
  z-index: 1;
  bottom: 16px;
  right: 32px;
}

.self-send-options {
  padding-top: 30px;
  border-top: 2px solid rgba($tan, 0.3);
  margin-bottom: 30px;

  .ss-question {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
  }

  .well {
    min-height: 120px;
    display: flex;
    cursor: pointer;

    .fa {
      color: $green;
      font-size: 24px;
      margin-right: 16px;
      position: relative;
      top: -6px;
    }

    strong {
      color: $blue;
      margin-bottom: 12px;
      font-size: 14px;
    }

    p {
      color: $gray;
      line-height: 1.4;
      font-size: 14px;
    }
  }
}

.toggle-actions {
  border-radius: 30px;
  padding: 4px 8px;
  width: 62px;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.08);

  position: absolute;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);

  &:before {
    content: '';
    top: -21px;
    border-style: solid;
    border-color: transparent transparent white transparent;
    border-width: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
  }

  .fa {
    opacity: 0.3;
    cursor: pointer;

    &.selected {
      opacity: 1;
    }
  }

  .fa-check {
    color: $green;
  }

  .fa-times {
    color: $red;
  }

  .toggle-text {
    color: $gray;
    font-size: 12px;
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 0.5px;
    width: max-content;
  }
}

.center-option {
  border: 2px solid #eee;
  border-radius: 4px;
  padding: 12px;
  font-size: 13px;
  letter-spacing: 1px;
  background: white;
  cursor: pointer;
  line-height: 12px;
  text-align: center;
  min-width: 80px;

  .fa {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
  }
}

.content-editable {
  padding: 12px 4px;
  border-bottom: 1px dashed rgba($dark-gray, 0.3);
  color: $dark-gray;
  @include lato;
  outline: none;
  font-size: 14px;
  letter-spacing: 1px;
  background: transparent;
  line-height: 1.4;
  resize: none;
  min-width: 150px;
  position: relative;
  cursor: text;

  &.invalid {
    color: $red;
    border-color: $red;
  }

  &.disallow {
    border: none;
    cursor: default;
  }

  &:empty:not(:focus):before {
    content: attr(placeholder);
    color: grey;
    font-style: italic;
  }
}

.road-block {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(white, 0.5);

  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 40px 32px;
    @include box-shadow-big;
    width: 70%;
    max-width: 600px;
    border: 1px solid #ddd;

    @include respond-to('sm') {
      width: 95%;
    }
  }

  .form-holder {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    @include respond-to('sm') {
      width: 95%;
    }
  }

  input[type='tel'] {
    padding: 12px;
    border: 2px solid $light-gray;
    padding: 24px;
    font-size: 18px;
    letter-spacing: 1px;
    outline: none;

    @include respond-to('sm') {
    }
  }

  .verification-input {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 10px;
    outline: none;
    border: none;
    border-bottom: 2px solid $light-gray;
    padding: 12px 0;
    text-align: center;

    @include respond-to('sm') {
      font-size: 24px;
    }
  }
}

.dupe-contact {
  border: 1px solid $light-gray;
  border-bottom: none;
  padding: 16px;
  transition: all 300ms;

  &:last-of-type {
    border-bottom: 1px solid $light-gray;
  }

  h4 {
    font-size: 14px;
    cursor: pointer;
  }

  p {
    background: darken($light-blue, 3%);
    border-radius: 8px;
    margin-bottom: 2px;
    display: inline-block;
    padding: 12px;
    line-height: 1.2;
    font-size: 14px;
    white-space: pre-line;
  }

  .date-sent {
    display: block;
    font-size: 11px;
    color: $gray;
    margin-bottom: 16px;
  }
}
