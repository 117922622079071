@import 'mixins';
@import 'variables';

.btn {
  padding: 14px 30px;
  @include lato;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  color: $dark-gray;
  background-color: #fff;
  border-radius: 3px;
  transition: all 250ms ease 0s;
  cursor: pointer;
  outline: none;
  border: none;
  line-height: 1;

  &:hover {
    background: darken(white, 1%);
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    background: darken($light-gold, 4%) !important;
    color: white;
    cursor: default !important;
  }

  &.btn-sm {
    padding: 10px 14px;
    font-size: 11px;
    letter-spacing: 1.5px;
  }

  &.btn-lg {
    padding: 15px 45px;
  }

  &.btn-cta {
    padding: 10px 16px 10px 38px;
    line-height: 2.3;
    font-size: 14px;
    white-space: nowrap;

    .fa {
      background: rgba(white, 0.2);
      font-size: 20px;
      padding: 6px 10px 6px 14px;
      margin-left: 10px;
      transition: transform 300ms;
      border-radius: 3px;
    }

    &:hover {
      .fa {
        transform: translateX(5px);
      }
    }
  }

  &.btn-blue {
    color: white;
    background: linear-gradient(to right, $blue, lighten($blue, 10%));

    &:hover {
      background: linear-gradient(to right, $blue, lighten($blue, 2%));
    }

    &:active {
      background: linear-gradient(to right, darken($blue, 5%), darken($blue, 2%));
    }
  }

  &.btn-clear {
    background: transparent;
  }

  &.btn-gold {
    color: white !important;
    background: linear-gradient(to right, $gold, lighten($gold, 12%));

    &:hover {
      background-color: darken($gold, 4%);
    }

    &:active {
      background-color: darken($gold, 8%);
    }
  }

  &.btn-red {
    color: white;
    background: $red;

    &:hover {
      background-color: darken($red, 4%);
    }

    &:active {
      background-color: darken($red, 8%);
    }
  }

  &.btn-border {
    border: 2px solid $light-gray;
  }

  &.btn-block {
    width: 100%;
    display: block;
    text-align: center;
  }

  &.btn-back {
    background: transparent;
    color: white;
    &:hover {
      background: transparent;
    }
  }
}

.danger-link {
  color: $red;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
}

.label {
  background: $light-gold;
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $dark-gray;
  padding: 3px 5px;
  border-radius: 3px;
  display: inline-block;

  &-success {
    background: lighten($green, 40%);
  }

  &-warning {
    background: lighten($red, 1%);
    color: white;
  }
}
