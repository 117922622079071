.external-nav {
  padding: 14px 0;
  height: $nav-height;
  display: flex;
  align-items: center;

  &.dark {
    background: transparent;
    position: relative;
    z-index: 1;
    a {
      color: white !important;
    }
  }

  .logo {
    @include logo;
    font-size: 32px;
    color: $dark-gray;

    @include respond-to('sm') {
      font-size: 26px;
    }
  }

  a:not(.logo) {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    margin-left: 15px;
    padding: 10px;
    color: $dark-gray;
    @include lato-medium;

    &:last-of-type {
      border: 1px solid;
      border-radius: 3px;
      &:hover {
        color: white;
        background: $dark-gray;
      }

      @include respond-to('md') {
        border: none;
      }
    }

    @include respond-to('sm') {
      font-size: 12px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  .pusher {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to('md') {
      flex-direction: column;
      margin-top: 24px;
    }
  }
}

.external-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  padding: 16px;
  background: white;

  @include respond-to('xs') {
    padding: 12px 0;
  }

  .logo {
    color: white;
    font-size: 32px;
    color: $dark-gray;

    @include respond-to('xs') {
      font-size: 26px;
    }
  }

  a:not(.logo) {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    margin-left: 15px;
    padding: 12px;
    color: $dark-gray;
    @include lato-medium;

    @include respond-to('sm') {
      font-size: 12px;
      margin-left: 10px;
    }

    &:last-of-type {
      margin-right: 24px;

      @include respond-to('xs') {
        margin-right: 12px;
      }
    }
  }
}

.header-active {
  .external-header {
    box-shadow: 0 4px 2px -2px rgba(black, 0.1);
  }
}

.external {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  &-footer {
    background: $dark-gray;
    color: white;
    padding: 48px 0;

    .logo {
      font-size: 24px;
      letter-spacing: 1px;
      margin-bottom: 32px;
    }

    h4 {
      color: rgba(white, 0.6);
      margin-bottom: 24px;
    }

    ul > li {
      margin-bottom: 24px;
      line-height: 1.4;
      font-size: 14px;
      color: rgba(white, 0.6);
    }

    a {
      color: white;
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }
}

.auth-page {
  flex-grow: 1;
  padding-bottom: 100px;
  background: $light-blue;
  transition: 500ms ease;
  margin-top: -80px;
  padding-top: 180px;

  @include respond-to('xs') {
    padding-top: 100px;
  }

  .well {
    width: 500px;
    margin: 0 auto;

    @include respond-to('xs') {
      width: 95%;
      margin: 0 auto;

      h1 {
        font-size: 24px;
      }
    }
  }
}

.section-header {
  font-size: 36px;
  font-weight: 900;
  margin-bottom: 12px;
  text-align: center;
  letter-spacing: 1px;
  color: $dark-gray;

  &.with-bb {
    margin-bottom: 40px;

    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 3px;
      background: $light-gold;
      margin: 0 auto;
      margin-top: 20px;
    }

    @include respond-to('xs') {
      margin-bottom: 24px;
    }
  }

  @include respond-to('sm') {
    font-size: 28px;
  }
}

#captcha-wrapper {
  margin-bottom: 24px;
}
