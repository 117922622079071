.home-intro {
  background: linear-gradient(45deg, rgba(38, 39, 48, 0.45) 0, rgba(38, 39, 48, 1) 100%);
  padding: 180px 0;
  height: 660px;
  text-align: center;
  color: white;
  position: relative;
  margin-top: -$nav-height;

  @include respond-to('sm') {
    padding: 160px 0;
    height: 600px;
  }

  @include respond-to('xs') {
    padding: 120px 0;
    height: 500px;
  }

  h1 {
    color: white;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 auto 32px auto;
    max-width: 870px;

    b {
      color: $tan;
      font-weight: 400;
    }

    @include respond-to('sm') {
      font-size: 42px;
      font-weight: 700;
    }

    @include respond-to('xs') {
      font-size: 24px;
      font-weight: 900;
      line-height: 1.3;
      b {
        font-weight: 900;
      }
    }
  }

  p {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;
    color: rgba(white, 0.75);
    line-height: 1.4;

    @include respond-to('sm') {
      font-size: 20px;
    }

    @include respond-to('xs') {
      font-size: 16px;
      line-height: 1.4;
      max-width: 80%;
      margin: 0 auto 40px auto;
      color: rgba(white, 0.85);
    }
  }

  .fullscreen-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: black;
    z-index: -100;

    &-video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .svg-wave {
    height: 150px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @include respond-to('sm') {
      height: 80px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.page-section {
  padding: 120px 0;
  position: relative;
  @include respond-to('sm') {
    padding: 70px 0;
  }

  &.intro {
    background-image: url('https://res.cloudinary.com/handwrite/image/upload/v1581446979/landing_pages/landing_texture_jintab.png'),
      linear-gradient(35deg, rgba(darken($blue, 10%), 0.9) 0%, rgba($blue, 0.97) 100%);
    background-size: cover;
    background-position: top center;
    padding-top: 80px;

    .angled {
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0px;
      z-index: 0;
      pointer-events: none;

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .intro-wrapper {
      text-align: center;
      max-width: 940px;
      padding: 0 50px;
      color: white;
      margin: 0 auto;

      @include respond-to('sm') {
        padding: 0;
      }
    }

    h1 {
      font-size: 48px;
      line-height: 1.3;
      font-weight: 600;
      color: inherit;

      @include respond-to('md') {
        font-size: 42px;
      }

      @include respond-to('sm') {
        font-size: 28px;
      }
    }

    .subhead {
      font-size: 27px;
      color: inherit;
      line-height: 1.4;
      padding: 0 80px;
      margin-bottom: 42px;
      opacity: 0.75;

      @include respond-to('sm') {
        padding: 0;
        font-size: 18px;
      }
    }

    .vid-wrapper {
      box-shadow: 0 10px 20px rgba(black, 0.2);
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 0px;
      border: none;
      background: white;
      width: 80%;
      margin: 0 auto;

      @include respond-to('md') {
        width: 75%;
      }

      .carousel {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      video {
        width: 100%;
      }
    }
  }

  &.stats {
    background: darken($blue, 8%);
    color: white;
    .box {
      padding: 40px 20px;
      border-radius: 6px;
      background-image: linear-gradient(to right, $blue, lighten($blue, 5%));
      text-align: center;

      span {
        color: rgba(white, 0.6);
        letter-spacing: 0.5px;
        line-height: 1.2;
      }

      .fa {
        margin-bottom: 24px;
        font-size: 24px;
        color: darken($blue, 8%);
      }

      strong {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 12px;
        display: block;
      }
    }

    .stat-text {
      color: white;
      margin-top: 100px;

      @include respond-to('sm') {
        margin-top: 40px;
      }

      h2,
      p {
        color: inherit;
        line-height: 1.5;
        margin-bottom: 32px;
      }
      p {
        font-size: 17px;
        opacity: 0.75;
      }
    }
  }

  &.what-for {
    padding-top: 0;

    strong {
      color: $green;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }

    .card {
      padding: 70px 40px 40px;
      border-radius: 6px;
      position: relative;
      box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.15), 0 3px 7px -3px rgba(0, 0, 0, 0.2);
      @include respond-to('sm') {
        margin-bottom: 42px;
      }
    }

    .fa {
      text-align: left;
      color: $blue;
      font-size: 32px;
      background: white;
      margin-bottom: 24px;
      padding: 18px;
      background: $light-blue;
      border-radius: 50%;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 8px rgba(black, 0.1);
    }

    p,
    h3 {
      margin-bottom: 12px;
      line-height: 1.6;
    }

    h3 {
      @include respond-to('sm') {
        font-size: 22px;
        line-height: 1.2;
      }
    }

    p {
      color: $gray;
    }
  }

  &.testimonials {
    text-align: center;
    padding-top: 0;

    h2 {
      font-size: 28px;
    }

    strong {
      color: $green;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }

    img {
      height: 50px;
      width: auto;
      align-self: center;
      opacity: 0.4;
      margin-bottom: 24px;
    }

    @include respond-to('sm') {
      padding-bottom: 50px;
      .spacer {
        flex-direction: column;
      }
    }

    .testi {
      padding: 40px;
      border-radius: 4px;
      border: 1px solid darken($light-blue, 5%);
      text-align: left;

      &:before {
        content: '\f10d';
        font: normal normal normal 18px/1 'FontAwesome';
        margin-bottom: 8px;
        display: inline-block;
        color: $tan;
      }

      @include respond-to('sm') {
        margin-bottom: 24px;
      }

      p {
        font-size: 19px;
        font-weight: 300;
        line-height: 1.7;
        display: block;
        margin-bottom: 24px;
      }

      > strong {
        font-size: 15px;
        color: $blue;
        display: block;
      }

      > span {
        color: $gray;
      }
    }

    .stars {
      margin-top: 24px;
      .fa {
        color: $yellow;
        margin-right: 4px;
      }
    }
  }

  &.integrations {
    background-color: $light-blue;
    background-image: url('https://res.cloudinary.com/handwrite/image/upload/v1581446978/landing_pages/landing_map_ksnxgy.png');
    background-repeat: no-repeat;
    background-position: center;
    img {
      width: 100%;
    }

    h3 {
      font-weight: 400;
      line-height: 1.3;
      margin-bottom: 48px;
      font-size: 42px;

      @include respond-to('sm') {
        font-size: 24px;
      }
    }

    ul {
      margin-bottom: 42px;
      li {
        font-size: 17px;
        line-height: 1.5;
        margin-bottom: 24px;

        > p {
          overflow: hidden;
          color: $dark-gray;
          font-weight: 400;
        }
      }
    }
  }

  .bottom-text {
    color: $gray;
    font-size: 18px;
  }
}

.for-page {
  .final-prompt {
    padding: 80px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: $light-blue;
    p {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  -webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  position: absolute;
  z-index: 2;
  top: 20px;
  background: 0 0;
  border: 0;
  font-size: 32px;
  cursor: pointer;
}
.carousel .control-arrow:hover {
  opacity: 1;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: '';
}
.carousel .control-disabled.control-arrow {
  opacity: 0;
  cursor: inherit;
  display: none;
}
.carousel .control-prev.control-arrow {
  left: 0;
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}
.carousel .control-next.control-arrow {
  right: 0;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}
.carousel {
  position: relative;
  width: 100%;
}
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none;
}
.carousel .carousel {
  position: relative;
}
.carousel .control-arrow {
  outline: 0;
  border: 0;
  background: 0 0;
  top: 50%;
  margin-top: -13px;
  font-size: 18px;
}
.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}
.carousel .thumbs {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
}
.carousel .thumb {
  -webkit-transition: border 0.15s ease-in;
  -moz-transition: border 0.15s ease-in;
  -ms-transition: border 0.15s ease-in;
  -o-transition: border 0.15s ease-in;
  transition: border 0.15s ease-in;
  display: inline-block;
  width: 80px;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
}
.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: 0;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #333;
}
.carousel .thumb img {
  vertical-align: top;
}
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2);
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -ms-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
}
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  background: #000;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  text-align: center;
  width: 100%;
}
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff;
}
.carousel:hover .slide .legend {
  opacity: 1;
}

.faqs {
  padding-top: 120px;
  strong {
    font-size: 17px;
    margin-bottom: 8px;
    line-height: 1.3;

    @include respond-to('sm') {
      font-size: 16px;
    }
  }

  .answers {
    line-height: 1.5;
    margin-bottom: 48px;
    color: $gray;

    @include respond-to('sm') {
      margin-bottom: 32px;
    }
  }
}

.signup-promo {
  padding: 100px 0;
  background: $dark-gray;
  text-align: center;
  border-bottom: 2px dashed rgba(white, 0.05);

  @include respond-to('sm') {
    padding: 70px 0;
  }

  > p {
    font-size: 24px;
    letter-spacing: 1px;
    margin-bottom: 32px;
    line-height: 1.4;
    color: white;

    @include respond-to('sm') {
      font-size: 20px;
      padding: 0 16px;
    }
  }
}

.terms > *,
.privacy > * {
  margin-top: 20px;
  line-height: 1.2;

  li {
    margin-bottom: 16px;
    list-style: disc;
    list-style-position: inside;
  }
}
