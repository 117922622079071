$orange-c-300: #978272;
$cream-c-300: #e3ded1;

.lifted {
  font-size: 12px;
  top: 10px;
  line-height: 16px;
  color: $gray;
}

label.input-label {
  position: absolute;
  left: 26px;
  top: 22px;
  line-height: 24px;
  z-index: 0;
  font-size: 14px;
  letter-spacing: 0.9px;
  text-align: left;
  transition: all 200ms ease;
  pointer-events: none;
  color: $gray;
  @include lato;

  &.lifted {
    @extend .lifted;
  }
}

.form-control-field {
  position: relative;
  margin-bottom: 24px;
  display: block;
  width: 100%;

  @include respond-to('xs') {
    margin-bottom: 20px;
  }

  &.active-error {
    input,
    select,
    textarea {
      border: 2px solid $red;
    }
  }

  > input,
  select,
  textarea,
  .read-only {
    background: white;
    display: block;
    width: 100%;
    border: 2px solid $light-gray;
    padding: 24px 0 8px 24px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 26px;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    z-index: 0;
    @include lato;

    &:focus + label {
      @extend .lifted;
    }

    @include respond-to('xs') {
      appearance: none;
      -webkit-appearance: none;
      min-height: 66px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .read-only {
    background: transparent;
    cursor: not-allowed;
    padding: 24px 48px 12px 24px;

    &:hover {
      .contact-text {
        opacity: 1;
      }
    }

    &-val {
      position: relative;
      left: 2px;
      top: 2px;
    }

    .contact-text {
      background: $orange-c-300;
      position: absolute;
      color: white;
      padding: 8px 40px 8px 16px;
      max-width: 256px;
      top: 8px;
      right: 8px;
      bottom: 8px;
      opacity: 0;
      transition: opacity 400ms ease;
      border-radius: 2px;
      display: flex;
      align-items: center;

      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  textarea {
    resize: none;
    height: 156px;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    z-index: 0;
    background-image: url('https://res.cloudinary.com/handwrite/image/upload/v1572368955/assets/caret-down_bc8csi_1_sbq41a.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
  }

  .fa-lock,
  .fa-caret-down {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: $gold;
    pointer-events: none;
  }

  .fa-lock {
    font-size: 20px;
  }

  .fa-caret-down {
    font-size: 10px;
    z-index: 0;
  }
}
.helper-text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  display: block;
  color: #555;

  &.error {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    color: $red;
  }

  &.char-length {
    right: 0;
    left: auto;
  }

  @include respond-to('xs') {
    font-size: 11px;
  }
}

.checkbox-field {
  padding-left: 22px;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: inline-block;
  margin-right: 12px;
  font-weight: 700;

  &:hover {
    color: black;
  }

  &.disabled {
    color: $gray;
    cursor: default;

    .fake-checkbox {
      border: 1px solid $gray;
    }

    input:checked ~ .fake-checkbox {
      background: $gray;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .fake-checkbox {
      background: $dark-gray;

      &:after {
        display: block;
      }
    }
  }

  .fake-checkbox {
    position: absolute;
    top: 5px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: white;
    border: 1px solid $dark-gray;
    border-radius: 2px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 12px;
      height: 12px;
      border: 2px solid white;
      top: 0;
      left: 0;
    }
  }
}

.select-wrapper {
  background: white;
  display: block;
  width: 100%;
  position: relative;
  padding-top: 24px;
  padding-bottom: 4px;
  border: 2px solid $light-gray;
  margin-bottom: 32px;
  label {
    @include lato;
  }

  &.active-error {
    border: 2px solid $red;
  }
}

.radio-section {
  @include lato;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: left;

  &.capitalized {
    label {
      text-transform: capitalize;
    }
  }

  label {
    position: relative;
    line-height: 22px;
    margin-right: 24px;
    padding: 7px 0;
    cursor: pointer;
    border-radius: 12px;

    &:hover {
      background: $light-blue;
    }

    &.disabled {
      cursor: default;
      color: $gray;
      &:hover {
        background: transparent;
      }
    }

    @include respond-to('xs') {
      display: block;
      margin-bottom: 16px;
    }
  }

  .radio-button {
    display: inline;
    padding-right: 8px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 100%;
      left: 0;
      top: 9px;
      border-radius: 20px;
      border: 1px solid transparent;
      transition: 0.3s all;
    }

    &::before {
      border: 1px solid $dark-gray;
    }

    &::after {
      width: 14px;
      height: 14px;
      left: 1px;
      top: 10px;
    }
  }

  input[type='radio'] {
    margin-right: 8px;
    visibility: hidden;
    opacity: 0;
  }

  input:checked + .radio-button::before {
    background-color: $dark-gray;
    // border: 1px solid $dark-gray;
  }

  input:checked + .radio-button::after {
    background-color: $dark-gray;
    border: 2px solid white;
  }
}

.multi-select {
  // pretty annoying but have to use important on all these
  &__value-container {
    padding: 0 32px 0 24px !important;
  }

  &__control {
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    background: transparent !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__indicator {
    display: none !important;
  }

  &__option {
    @include lato;
    letter-spacing: 1px !important;
    cursor: pointer !important;
    background-color: white !important;

    &:hover {
      background-color: $light-gray !important;
    }
  }

  &__menu {
    border-radius: 0 !important;
  }

  &__multi-value {
    background: $light-gray !important;
    text-transform: uppercase !important;
    @include lato-bold;
    letter-spacing: 1.2px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    border: 1px solid $cream-c-300 !important;

    &__label {
      color: $orange-c-300 !important;
      padding: 0 6px !important;
    }

    &__remove {
      color: $orange-c-300 !important;
      border-radius: 0 !important;
      border-left: 1px solid $cream-c-300 !important;
      padding: 0 6px !important;
      &:hover {
        background: transparent !important;
        color: inherit !important;
      }
    }
  }
}

.autocomplete-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  max-height: 324px;
  overflow: auto;
  background: white;

  li {
    padding: 12px 36px;
    font-size: 12px;
    cursor: pointer;
    @include lato;

    &.active {
      background: $light-gray;
    }

    @include respond-to('xs') {
      padding: 12px 24px;
      text-align: left;
      line-height: 1.3;
    }
  }
}

.inline-fields {
  display: flex;
  > * {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  @include respond-to('sm') {
    flex-direction: column;
  }
}

.inline-btn-field {
  display: flex;
  .form-control-field {
    margin-bottom: 0;
  }

  .btn {
    margin-left: -4px;
  }
}

.search-wrapper {
  position: relative;
  flex-grow: 1;

  .fa {
    position: absolute;
    left: 16px;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.35;

    &-spin {
      top: 38%;
    }
  }
}

.search-bar {
  display: block;
  width: 100%;
  padding: 16px 0 16px 45px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1;
  color: $dark-gray;
  border: 2px solid $light-gray;
  outline: none;
  border-radius: 3px;

  &::placeholder {
    color: $gray-d;
  }

  @include respond-to('xs') {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0 !important;
    font-size: 16px;
  }
}

.file-input {
  display: flex;
  flex-direction: column;
  border: 2px dashed $gray;
  padding: 30px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  .fa {
    color: $blue;
    font-size: 28px;
    display: block;
    margin: 0 auto 16px auto;
  }

  > input[type='file'] {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 40px;
  height: 22px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: $orange;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $orange;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 19px;
  border-color: $orange;
}

.toggle-label {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  > * {
    margin-right: 10px;
  }

  &.checked {
    color: $orange;
  }

  &.pull-right {
    > * {
      margin-left: 10px;
    }
  }
}

.faux-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: 0 0 4px rgba($blue, 0.25);
  border-radius: 2px;
  background: white;

  &-inner {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 2px;

    &.checked {
      box-shadow: 0 0 10px rgba($green, 0.55);
      background: $green;
      border: 2px solid white;
    }
  }
}

.simple-input {
  display: block;
  width: 100%;
  border: 1px solid $gray-d;
  padding: 12px;
  border-radius: 4px;
}
