.public-proofs {
  max-width: 800px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 20px 0 60px 0;

  img {
    width: 100%;
    border-radius: 6px;
    filter: brightness(1.5) hue-rotate(50deg) saturate(0.4);
    @include box-shadow-big;
  }

  .logo {
    font-size: 32px;
    margin-bottom: 60px;
  }

  h1 {
    font-size: 40px;

    @include respond-to('sm') {
      font-size: 20px;
    }
  }

  .share-section {
    margin-top: 40px;
  }
}

.image-proofs {
  a {
    width: 49%;
    display: inline-block;
    position: relative;

    .fa {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      color: white;
      filter: drop-shadow(0 0 12px black);
    }

    &:first-of-type {
      margin-right: 2%;
    }

    &:hover {
      .fa {
        display: block;
      }
    }

    img {
      width: 100%;
      filter: brightness(1.5) hue-rotate(50deg) saturate(0.4);
    }
  }
}

.admin-controls {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 10px 12px 10px 0;
  background: $light-blue;
  z-index: 1;
  margin-right: -12px;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-bottom {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 13px;
    text-transform: uppercase;
    line-height: 19px;
    margin-left: 24px;
    cursor: pointer;
    color: #444;
    font-weight: 700;
  }

  input[type='checkbox'] {
    transform: scale(1.4);
    margin-right: 8px;
    cursor: pointer;
  }
}

.highlighter {
  display: block;
  > * {
    display: inline;
  }

  .highlighted {
    background: greenyellow;
  }
}
