.dropdown {
  position: relative;
  cursor: pointer;

  &:hover {
    .dropdown-menu {
      visibility: visible;
    }
  }

  &-menu {
    position: absolute;
    top: calc(100%);
    right: 0;
    background: white;
    box-shadow: 0 0 18px rgba($dark-gray, 0.15);
    width: max-content;
    max-width: 300px;
    min-width: 100px;
    padding: 10px 0;
    visibility: hidden;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      right: 3px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
    }
  }

  ul {
    li {
      color: $gray;
      padding: 10px 16px;
      font-size: 12px;
      letter-spacing: 0.5px;
      display: block;
      text-transform: uppercase;
      text-align: left;
      font-weight: 700;
      line-height: 1;
      &:hover {
        color: $dark-gray;
      }

      .fa {
        margin-right: 4px;
      }
    }
  }
}
