.light {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red-light {
    background: #ed0918;
  }

  &.green-light {
    background: $green;
  }
}

.pen-life {
  position: relative;
  height: 20px;
  width: 180px;
  border: 1px solid #eee;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;

  &-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    text-align: center;
  }

  > span {
    font-weight: bold;
    font-size: 10px;
    z-index: 1;
    position: relative;
  }
}
