.tabs {
  border-bottom: 1px solid darken($light-gray, 3%);
  margin-bottom: 48px;

  &.full-width li {
    flex-grow: 1;
  }

  ul {
    display: flex;

    li {
      display: inline-block;

      a,
      .inner-tab {
        padding: 0 24px;
        padding-top: 16px;
        padding-bottom: 16px;
        display: block;
        text-align: center;
        border: 2px solid transparent;
        margin-bottom: -3px;
        color: $gray;
        font-weight: 700;
        cursor: pointer;

        &:hover {
          color: $dark-gray;
        }

        @include respond-to('xs') {
          padding: 10px 14px;
          font-size: 14px;
        }

        &.active {
          border-bottom: 2px solid $blue;
          margin-bottom: -2px;
          color: $blue;
        }

        > svg {
          opacity: 0.6;
          margin-right: 8px;
          color: $blue;
        }
      }
    }
  }
}

.vertical-tabs {
  ul {
    li {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.25px;
      padding: 16px 12px;
      border-radius: 3px;
      cursor: pointer;
      color: $blue;

      &.active {
        color: white;
        background: $blue;
        @include box-shadow;
      }
    }
  }
}
