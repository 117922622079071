.standout {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 13px;
  line-height: 24px;
  color: $dark-gray;
  display: inline-block;
  text-decoration: none;
}

.breadcrumbs {
  color: $dark-gray;
  list-style: none;

  li {
    margin-right: 8px;
    display: inline-block;
    @extend .standout;
    color: $gold;

    &:not(:first-of-type):before {
      content: '/';
      margin-right: 8px;

      @include respond-to('xs') {
        font-size: 10px;
        line-height: 24px;
        vertical-align: top;
      }
    }
  }

  a {
    color: inherit;
    @extend .standout;
    border-bottom: 1px dashed rgba($gold, 0.5);

    &:hover {
      border-bottom: 1px solid rgba($gold, 0.4);
    }

    @include respond-to('xs') {
      font-size: 10px;
    }
  }
}
