.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: white;
  transform: translateX(100%);
  width: 400px;
  transition: transform 200ms linear;
  z-index: 2;
  overflow: auto;

  @include respond-to('sm') {
    width: 300px;
  }

  &-open {
    transform: translateX(0);
  }

  &-closer {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(black, 0.15);
  }
}
