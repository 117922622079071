*,
*:before,
*:after {
  box-sizing: inherit;
  vertical-align: top;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  line-height: 1;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: #262730;
}

span,
strong {
  display: inline-block;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

ul li {
  list-style: none;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
}

/* No number spinners on input fields */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
}

input[type='search'] {
  -webkit-appearance: textfield; /* https://github.com/h5bp/html5-boilerplate/issues/396#issue-697226 */
}

.classic-list li {
  list-style: disc;
  font-size: 14px;
  margin-bottom: 12px;
  margin-left: 20px;
  line-height: 1.4;
}

code {
  color: darken(#d73a49, 10%);
  background: #f9f9f9;
  padding: 1px 3px;
  font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', monospace;
  font-size: 13px;
  line-height: 1.6;
}
